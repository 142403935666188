import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import { isFeatureEnabled } from 'owa-feature-flags';
import React from 'react';
import getCoprincipalOrGlobalSettingsMailboxInfo from './getCoprincipalOrGlobalSettingsMailboxInfo';

import type { MailboxInfo } from 'owa-client-types';
import type { PlacesTenantSettingComplete } from './types/PlacesTenantSettingCompleteCallback';

export { PlacesSettings } from './types/PlacesSettings';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesSettings" */ './lazyIndex')
);

const lazyIsLoading = new LazyImport(lazyModule, m => m.isLoading);
const lazyHasFailed = new LazyImport(lazyModule, m => m.hasFailed);
const lazyIsPlacesWebAppEnabled = new LazyImport(lazyModule, m => m.isPlacesWebAppEnabled);
const lazyIsPlacesPremiumEnabled = new LazyImport(lazyModule, m => m.isPlacesPremiumEnabled);
const lazyIsPlacesCopilotEnabled = new LazyImport(lazyModule, m => m.isPlacesCopilotEnabled);
const lazyIsPlacesFinderEnabled = new LazyImport(lazyModule, m => m.isPlacesFinderEnabled);
const lazyIsOpxPlacesFinderEnabled = new LazyImport(lazyModule, m => m.isOpxPlacesFinderEnabled);
const lazyIsPlacesHybridGuidanceEnabled = new LazyImport(
    lazyModule,
    m => m.isPlacesHybridGuidanceEnabled
);
const lazyLoadPlacesSettings = new LazyAction(lazyModule, m => m.loadPlacesSettings);

export function isPlacesWebAppEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyIsPlacesWebAppEnabled.tryImportForRender()?.(mailboxInfo);
}

export function isPlacesPremiumEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyIsPlacesPremiumEnabled.tryImportForRender()?.(mailboxInfo);
}

export function isPlacesCopilotEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyIsPlacesCopilotEnabled.tryImportForRender()?.(mailboxInfo);
}

export function isPlacesFinderEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyIsPlacesFinderEnabled.tryImportForRender()?.(mailboxInfo);
}

export function asyncIsPlacesFinderEnabled(mailboxInfo: MailboxInfo): Promise<boolean> {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    return lazyIsPlacesFinderEnabled
        .import()
        .then(isPlacesFinderEnabledFn => isPlacesFinderEnabledFn(mailboxInfo));
}

export function isOpxPlacesFinderEnabled(mailboxInfo: MailboxInfo): boolean | undefined {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    return (
        isFeatureEnabled('msplaces-use-tenant-settings-for-opx') &&
        /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
         * BASELINE. Do not copy and paste!
         *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
        lazyIsOpxPlacesFinderEnabled.tryImportForRender()?.(mailboxInfo)
    );
}

export function asyncIsOpxPlacesFinderEnabled(mailboxInfo: MailboxInfo): Promise<boolean> {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    if (!isFeatureEnabled('msplaces-use-tenant-settings-for-opx')) {
        return Promise.resolve(false);
    }
    return lazyIsOpxPlacesFinderEnabled
        .import()
        .then(isOpxPlacesFinderEnabledFn => isOpxPlacesFinderEnabledFn(mailboxInfo));
}

export function isPlacesExploreEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    return isPlacesPremiumEnabled(mailboxInfo);
}

export function isPlacesSearchEnabled(mailboxInfo: MailboxInfo) {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    return isPlacesPremiumEnabled(mailboxInfo);
}

export function isPlacesWorkGroupsEnabled(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyIsPlacesHybridGuidanceEnabled.tryImportForRender()?.(mailboxInfo);
}

function hasFailed(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return !!lazyHasFailed.tryImportForRender()?.(mailboxInfo);
}

function isLoading(mailboxInfo: MailboxInfo): boolean {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);
    /* eslint-disable-next-line no-restricted-properties -- (https://aka.ms/OWALintWiki)
     * BASELINE. Do not copy and paste!
     *	> 'tryImportForRender' is restricted from being used. tryImportForRender should only be used in rare circumstances and can hurt TTI if used incorrectly. */
    return lazyIsLoading.tryImportForRender()?.(mailboxInfo) !== false;
}

function noAccess(mailboxInfo: MailboxInfo) {
    return !isPlacesWebAppEnabled(mailboxInfo);
}

export function usePlacesSettings(
    mailboxInfo: MailboxInfo,
    shouldUseMainThread?: boolean,
    onPlacesTenantSettingComplete?: PlacesTenantSettingComplete
) {
    mailboxInfo = getCoprincipalOrGlobalSettingsMailboxInfo(mailboxInfo);

    const loadPlacesSettings = React.useCallback(() => {
        lazyLoadPlacesSettings.importAndExecute(
            mailboxInfo,
            shouldUseMainThread,
            onPlacesTenantSettingComplete
        );
    }, [mailboxInfo, shouldUseMainThread, onPlacesTenantSettingComplete]);

    React.useEffect(() => {
        loadPlacesSettings();
    }, [loadPlacesSettings]);

    return {
        loading: isLoading(mailboxInfo),
        failed: hasFailed(mailboxInfo),
        noAccess: noAccess(mailboxInfo),
        retry: loadPlacesSettings,
    };
}
