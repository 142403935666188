import type CalendarsCacheStore from './schema/CalendarsCacheStore';
import { createStore } from 'owa-satcheljs';
const defaultCalendarsCacheStore: CalendarsCacheStore = {
    calendarEntryMapping: new Map(),
    folderIdToCalendarId: new Map(),
    validEntryMapping: new Map(),
    calendarGroupsMapping: new Map(),
    defaultCalendars: new Map(),
    calendarIdOrderedList: [],
    calendarGroupKeyOrderedList: [],
};

const calendarsCacheStore = createStore<CalendarsCacheStore>(
    'calendarsCacheStore',
    defaultCalendarsCacheStore
)();
export default () => calendarsCacheStore;
