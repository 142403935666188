import React from 'react';
import { logGreyError } from 'owa-analytics';

/**
 * Context for the WorkPlansDailyPeek component. This type may be expanded and used for properties
 * that would otherwise be plumbed through multiple layers of components.
 */
export type WorkPlansDailyPeekContextType = {
    onSeeMoreClicked: ((url: string) => void) | undefined;
};

const defaultWorkPlansDailyPeekContextValue: WorkPlansDailyPeekContextType = {
    onSeeMoreClicked: undefined,
};

export const WorkPlansDailyPeekContext = React.createContext<WorkPlansDailyPeekContextType | null>(
    defaultWorkPlansDailyPeekContextValue
);

export const useWorkPlansDailyPeekContextProvider = () => {
    const context = React.useContext(WorkPlansDailyPeekContext);

    if (context === undefined) {
        logGreyError('WorkPlansDailyPeek_ContextProvider_Error');
        return defaultWorkPlansDailyPeekContextValue;
    } else {
        return context;
    }
};
