import type CalendarCloudSetting from './schema/CalendarCloudSetting';
import type CalendarCloudSettingsStore from './schema/CalendarCloudSettingsStore';
import { createStore } from 'owa-satcheljs';
import type { CalendarCloudSettingStoreMap } from './schema/CalendarCloudSettingStoreMap';

const defaultStore: CalendarCloudSettingStoreMap = {
    accounts: new Map<string, CalendarCloudSettingsStore>(),
};

export const getAccountDefaultSettings = (): CalendarCloudSettingsStore => ({
    settings: new Map<string, CalendarCloudSetting>(),
    isInitialized: false,
});

export const getStore = createStore('CalendarCloudSettings', defaultStore);
