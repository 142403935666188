import {
    lazyCleanUpPlacesIndexedDB,
    lazyCleanUpPlacesLocalStorage,
    lazyCleanUpPlacesSessionStorage,
} from './lazyFunctions';

export async function executeSignoutPromises() {
    await Promise.all([
        lazyCleanUpPlacesLocalStorage.importAndExecute(),
        lazyCleanUpPlacesSessionStorage.importAndExecute(),
        lazyCleanUpPlacesIndexedDB.importAndExecute(),
    ]);
}
