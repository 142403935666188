import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'owa-satcheljs';

import type { Location } from 'owa-graph-schema';

import type { OwaDate } from 'owa-datetime';
const { setOccurringFWH, getOccurringFWH, setRecurringFWH, getRecurringFWH } = createAccessors(
    'hybridspace-user-settings',
    createStore('hybridspace-user-settings', {
        occurringFWH: new Array<{
            date: OwaDate;
            workLocation: string;
            workLocationDetails?: Location[];
        }>(),
        recurringFWH: new Array<{
            date: OwaDate;
            workLocation: string;
            workLocationDetails?: Location[];
        }>(),
    })
);

export { setOccurringFWH, getOccurringFWH, setRecurringFWH, getRecurringFWH };
