import { getCalendarEntryByFolderId } from 'owa-calendar-cache';
import { isLinkedCalendarEntry, isLocalCacheForRemoteCalendarEntry } from 'owa-calendar-properties';
import type {
    CalendarEntry,
    LinkedCalendarEntry,
    LocalCacheForRemoteCalendarEntry,
    LocalCalendarEntry,
} from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import {
    accountSourceDataTypeChecker,
    getCoprincipalAccountForMailboxInfo,
    getCoprincipalMailboxInfoForMailboxInfo,
    isGlobalSettingsMailbox,
} from 'owa-account-source-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getOwaWorkload, type OwaWorkload } from 'owa-workloads';
import { getMailboxInfoForShared } from 'owa-mailbox-info/lib/getMailboxInfoForShared';
import { WorkLocationControlType } from './types';

export interface WorkLocationControlInfo {
    requiredControlType: WorkLocationControlType;
    sharedOwnerEmailAddress: string | null | undefined;
    mailboxInfo: MailboxInfo;
    isSecondaryMailbox: boolean;
}

export default function getWorkLocationControlRequirements(
    selectedMailboxInfo: MailboxInfo,
    calendarFolderId?: string
): WorkLocationControlInfo {
    const isPlacesEnabled =
        getOwaWorkload() === 16777216 || isFeatureEnabled('cal-surface-places-callout');

    let requiredControlType = isPlacesEnabled
        ? WorkLocationControlType.PlacesReadWrite
        : WorkLocationControlType.ReadWrite;
    let sharedOwnerEmailAddress: string | null | undefined = undefined;
    let folderMailboxInfo = undefined;
    let isSecondaryMailbox = false;

    // This flow handles both single calendar view and split view.
    if (calendarFolderId) {
        const calendarEntry: CalendarEntry | undefined =
            getCalendarEntryByFolderId(calendarFolderId);

        if (calendarEntry) {
            folderMailboxInfo = getCoprincipalMailboxInfoForMailboxInfo(
                calendarEntry.calendarId.mailboxInfo
            );
            if (isLinkedCalendarEntry(calendarEntry) && !calendarEntry.IsGroupMailboxCalendar) {
                // This covers our shared calendar scenario.
                requiredControlType = WorkLocationControlType.ReadOnly;
                sharedOwnerEmailAddress = (calendarEntry as LinkedCalendarEntry).OwnerEmailAddress;
            } else if (isLocalCacheForRemoteCalendarEntry(calendarEntry)) {
                // This covers our delegate calendars scenario.
                requiredControlType = WorkLocationControlType.ReadOnly;
                sharedOwnerEmailAddress = (calendarEntry as LocalCacheForRemoteCalendarEntry)
                    .OwnerEmailAddress;

                // We need to validate our delegate SMTP address and ensure that the coprincipal
                // mailbox is an M365 user mailbox before proceeding.
                if (
                    sharedOwnerEmailAddress &&
                    isFeatureEnabled('cal-surface-places-multi-account') &&
                    folderMailboxInfo &&
                    accountSourceDataTypeChecker.isM365UserMailbox(
                        getCoprincipalAccountForMailboxInfo(folderMailboxInfo)
                    )
                ) {
                    const sharedMailboxInfo = getMailboxInfoForShared(
                        folderMailboxInfo,
                        sharedOwnerEmailAddress,
                        calendarEntry.CalendarName
                    );
                    if (
                        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-surface-places-callout' */
                        isFeatureEnabled('cal-surface-places-callout', sharedMailboxInfo) &&
                        /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                         * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                         *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-surface-places-multi-account' */
                        isFeatureEnabled('cal-surface-places-multi-account', sharedMailboxInfo)
                    ) {
                        requiredControlType = isPlacesEnabled
                            ? WorkLocationControlType.PlacesReadWrite
                            : WorkLocationControlType.ReadWrite;
                        folderMailboxInfo = sharedMailboxInfo;
                        isSecondaryMailbox = true;
                    }
                }
            } else if (!(calendarEntry as LocalCalendarEntry).IsDefaultCalendar) {
                // This covers our non-shared non-primary calendar scenarios, such as Birthdays.
                requiredControlType = WorkLocationControlType.None;
            } else if (!isGlobalSettingsMailbox(calendarEntry.calendarId.mailboxInfo)) {
                // This covers our multi-account secondary account scenario.
                if (
                    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                     *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-surface-places-multi-account' */
                    !isFeatureEnabled('cal-surface-places-multi-account', folderMailboxInfo) &&
                    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
                     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
                     *	> The parameter mailboxInfo must be undefined. Feature flight: 'cal-surface-places-callout' */
                    isFeatureEnabled('cal-surface-places-callout', folderMailboxInfo)
                ) {
                    requiredControlType = WorkLocationControlType.ReadOnly;
                    sharedOwnerEmailAddress =
                        calendarEntry.calendarId.mailboxInfo.mailboxSmtpAddress;
                }
                isSecondaryMailbox = true;
            }
        }
    } else {
        // For our merged view scenario we need to explicitly check if the selected mailbox is a
        // secondary mailbox.
        isSecondaryMailbox = !isGlobalSettingsMailbox(selectedMailboxInfo);
    }

    return {
        requiredControlType,
        sharedOwnerEmailAddress,
        mailboxInfo: folderMailboxInfo ?? selectedMailboxInfo,
        isSecondaryMailbox,
    };
}
