import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'owa-satcheljs';

const { setShouldRunOnboardingExperience, getShouldRunOnboardingExperience } = createAccessors(
    'hybridspace-onboarding-module',
    createStore('hybridspace-onboarding-module', {
        shouldRunOnboardingExperience: false,
    })
);

export { setShouldRunOnboardingExperience, getShouldRunOnboardingExperience };
