import type {
    CalendarEntry,
    CalendarGroup,
    CalendarId,
    LinkedCalendarEntry,
    LinkedCalendarEntryV2,
    LocalCacheForRemoteCalendarEntry,
    LocalCalendarEntry,
} from 'owa-graph-schema';
import getCalendarGroupKey from '../utils/getCalendarGroupKey';
import getStore from '../store/store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import {
    getCalculatedFolderId,
    isLinkedCalendarEntry,
    isLinkedCalendarEntryV2,
} from 'owa-calendar-properties';
import { findInArray } from 'owa-calendar-data-utils/lib/findInArray';
import { isCalendarInAccount } from '../utils/compareCalendarUtils';
import {
    getAccountKeyForMailboxInfo,
    isSameCoprincipalAccountByMailboxAndAccountKey,
} from 'owa-client-types';
import type { AccountKey, MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export function getDefaultCalendar(accountKey?: AccountKey): CalendarEntry | null | undefined {
    return getStore().defaultCalendars.get(
        accountKey ? accountKey : getAccountKeyForMailboxInfo(getGlobalSettingsAccountMailboxInfo())
    );
}

export function isDefaultCalendar(calendarId: CalendarId, accountKey?: AccountKey): boolean {
    const defaultCalendar = getDefaultCalendar(accountKey);
    return !!defaultCalendar && calendarId.id === defaultCalendar.calendarId.id;
}

export function getCalendarEntries(): CalendarEntry[] {
    const { calendarEntryMapping, calendarIdOrderedList } = getStore();

    // always return ordered list
    const calendarEntries: CalendarEntry[] = [];
    for (const calendarId of calendarIdOrderedList) {
        const calendarEntry = calendarEntryMapping.get(calendarId);
        if (calendarEntry) {
            calendarEntries.push(calendarEntry);
        }
    }

    return calendarEntries;
}

export function getCalendarIndex(calendarId: string): number {
    const calendarIdFindResult = findInArray(
        getStore().calendarIdOrderedList,
        id => id === calendarId
    );
    if (calendarIdFindResult) {
        return calendarIdFindResult.index;
    } else {
        return -1;
    }
}

export function getCalendarIdOrderedList(): string[] {
    return getStore().calendarIdOrderedList;
}

export function getCalendarGroups(): CalendarGroup[] {
    const { calendarGroupsMapping, calendarGroupKeyOrderedList } = getStore();

    const calendarGroups: CalendarGroup[] = [];
    // always return ordered list
    for (const calendarGroupKey of calendarGroupKeyOrderedList) {
        const calendarGroup = calendarGroupsMapping.get(calendarGroupKey);
        if (calendarGroup) {
            calendarGroups.push(calendarGroup);
        }
    }

    return calendarGroups;
}

export function getCalendarGroupIndex(calendarGroupId: string, mailboxInfo: MailboxInfo): number {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const groupKey = getCalendarGroupKey(accountKey, calendarGroupId);
    const calendarGroupKeyFindResult = findInArray(
        getStore().calendarGroupKeyOrderedList,
        key => key === groupKey
    );
    if (calendarGroupKeyFindResult) {
        return calendarGroupKeyFindResult.index;
    } else {
        return -1;
    }
}

export function getCalendarGroupKeyOrderedList(): string[] {
    return getStore().calendarGroupKeyOrderedList;
}

export function getCalendarGroupByGroupId(
    calendarGroupId: string,
    mailboxInfo: MailboxInfo
): CalendarGroup | undefined {
    const { calendarGroupsMapping } = getStore();
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);
    const groupKey = getCalendarGroupKey(accountKey, calendarGroupId);
    return calendarGroupsMapping.get(groupKey);
}

/**
 * **Important:** Even when the calendar cache is initialized, it is not a guarantee that the folder id associated with an
 * event will be present in the cache, because some calendar folder ids need to be updated after initialization. This is true
 * of all cache functions that use calendar folder id to select data.
 * See `getAndUpdateActualFolderId` for details.
 *
 * @param folderId the calendar folder id
 */
export function getCalendarIdByFolderId(
    folderId: string | null | undefined
): CalendarId | undefined {
    if (folderId) {
        const { folderIdToCalendarId } = getStore();
        return folderIdToCalendarId.get(folderId);
    }

    return undefined;
}

export function getCalendarEntryByCalendarId(calendarId: string): CalendarEntry | undefined {
    const { calendarEntryMapping } = getStore();
    return calendarEntryMapping.get(calendarId);
}

export function getCalendarEntryByFolderId(
    folderId: string | null | undefined
): CalendarEntry | undefined {
    if (folderId) {
        const calendarId = getCalendarIdByFolderId(folderId);
        // If the calendar ID is present then get the CalendarEntry for it otherwise return undefined
        return calendarId ? getCalendarEntryByCalendarId(calendarId.id) : undefined;
    }

    return undefined;
}

export function getCalendarFolderId(calendar: CalendarEntry): string {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (131,5): Type 'string | null' is not assignable to type 'string'.
    // @ts-expect-error
    return getFolderIdByCalendarID(calendar.calendarId.id);
}

export function getFolderIdByCalendarID(calendarId: string): string | null {
    const { calendarEntryMapping } = getStore();
    const calendarEntry = calendarEntryMapping.get(calendarId);

    if (calendarEntry) {
        const folderId = getCalculatedFolderId(calendarEntry);
        return folderId ? folderId.Id : null;
    }

    return null;
}

export function getCalculatedFolderIdByCalendarID(calendarId: string): string | null {
    const { calendarEntryMapping } = getStore();

    const calendarEntry = calendarEntryMapping.get(calendarId);
    if (calendarEntry) {
        const folderId = getCalculatedFolderId(calendarEntry);
        return folderId ? folderId.Id : null;
    }
    return null;
}

export function getCalculatedFolderIdForDefaultCalendar(mailboxInfo?: MailboxInfo) {
    const accountKey = mailboxInfo ? getAccountKeyForMailboxInfo(mailboxInfo) : undefined;
    const defaultCalendar = getDefaultCalendar(accountKey);

    if (defaultCalendar) {
        const folderId = getCalculatedFolderId(defaultCalendar);
        return folderId ? folderId.Id : null;
    }

    return null;
}

export function calendarIsInCache(calendarId: string): boolean {
    return getCalendarEntryByCalendarId(calendarId) ? true : false;
}

/**
 * Returns whether this folder is a LocalCalendarEntry or a LocalCacheForRemoteCalendarEntry
 * that has a valid CalendarFolderId
 * @param folderId FolderId of the calendar folder
 */
export function isLocalCalendarEntry(folderId: string | null | undefined): boolean {
    if (!folderId) {
        return false;
    }
    const { folderIdToCalendarId, calendarEntryMapping } = getStore();

    const calendarId = folderIdToCalendarId.get(folderId);
    if (calendarId) {
        const calendarEntry: any = calendarEntryMapping.get(calendarId.id);

        // Group mailboxes are Linked calendar entries
        return (
            calendarEntry &&
            !calendarEntry.IsGroupMailboxCalendar &&
            !isLinkedCalendarEntry(calendarEntry) &&
            !isLinkedCalendarEntryV2(calendarEntry)
        );
    } else {
        return false;
    }
}

/**
 * Returns whether the given folder is a readonly local calendar entry
 * @param folderId The folder id
 * @returns True if it's a read only local calendar entry, false otherwise
 */
export function isReadOnlyLocalCalendar(folderId: string): boolean {
    if (isLocalCalendarEntry(folderId)) {
        const calendarEntry: LocalCalendarEntry = getCalendarEntryByFolderId(
            folderId
        ) as LocalCalendarEntry;
        return !!calendarEntry?.IsReadOnly;
    }
    return false;
}

/**
 * Checks whether the calendarEntry has been marked as an invalid folder
 * @param calendarEntry the calendar folder to check for validity
 */
export function isValidCalendarEntry(
    calendarEntry?: CalendarEntry,
    actionSource?: string
): boolean {
    const { validEntryMapping } = getStore();
    if (
        calendarEntry &&
        isFeatureEnabled('cal-enable-HybridModel-for-calendarSharing') &&
        isLinkedCalendarEntryV2(calendarEntry) &&
        actionSource == 'CalendarPickerSelection'
    ) {
        return true;
    }

    return !!calendarEntry && !!validEntryMapping.get(calendarEntry.calendarId.id);
}

/**
 * Checks if the calendar folder id needs to be updated.
 * @returns true if the calendar is an old-model shared calendar i.e. `LinkedCalendarEntry` calendar that has not been
 * updated via `getAndUpdateActualFolderId`, otherwise returns false.
 *
 * When the cache is initialized LinkedCalendarEntry
 * calendar entries do not have accurate folder ids. These ids can be fetched/ updated
 * using `getAndUpdateActualFolderId`. Once the folder Id is updated for the calendar, this
 * calendar entry is marked as valid, and `calendarFolderIdNeedsUpdate` will return false.
 */
export function calendarFolderIdNeedsUpdate(calendarId: string, actionSource?: string): boolean {
    const folderId = getFolderIdByCalendarID(calendarId);
    if (!isLocalCalendarEntry(folderId)) {
        const calendarEntry = getCalendarEntryByCalendarId(calendarId);
        return !isValidCalendarEntry(calendarEntry, actionSource);
    }
    return false;
}

export function getCalendarEntryByEmailAddress(
    emailAddress?: string
): CalendarEntry | null | undefined {
    if (!emailAddress) {
        return null;
    }
    const { folderIdToCalendarId, calendarEntryMapping } = getStore();
    const calendarId = folderIdToCalendarId.get(emailAddress);
    let calendarEntry;

    // If the calendar ID is present then get the CalendarEntry for it,
    // otherwise search through calendar entries for a LocalCacheForRemoteCalendarEntry
    if (calendarId) {
        calendarEntry = calendarEntryMapping.get(calendarId.id);
    } else {
        const entries = [...calendarEntryMapping.values()] as (
            | LinkedCalendarEntryV2
            | LocalCacheForRemoteCalendarEntry
            | LinkedCalendarEntry
        )[];

        calendarEntry = entries.filter(entry => {
            return (
                entry.OwnerEmailAddress &&
                entry.OwnerEmailAddress.toLowerCase() == emailAddress.toLowerCase()
            );
        })[0];
    }

    return calendarEntry;
}

export function getCalendarGroupsForUser(accountKey: AccountKey): {
    userCalendarGroups: CalendarGroup[];
    groupCalendarGroups: CalendarGroup[];
    teamsCalendarGroups: CalendarGroup[];
} {
    const calendarGroups = getCalendarGroups();
    const userCalendarGroups = calendarGroups.filter(
        calendarGroup =>
            calendarGroup.calendarGroupId.mailboxInfo.type === 'UserMailbox' &&
            isSameCoprincipalAccountByMailboxAndAccountKey(
                calendarGroup.calendarGroupId.mailboxInfo,
                accountKey
            )
    );
    const groupCalendarGroups = calendarGroups.filter(
        calendarGroup =>
            calendarGroup.calendarGroupId.mailboxInfo.type === 'GroupMailbox' &&
            isSameCoprincipalAccountByMailboxAndAccountKey(
                calendarGroup.calendarGroupId.mailboxInfo,
                accountKey
            )
    );

    const teamsCalendarGroups = calendarGroups.filter(
        calendarGroup =>
            calendarGroup.calendarGroupId.mailboxInfo.type === 'TeamsMailbox' &&
            isSameCoprincipalAccountByMailboxAndAccountKey(
                calendarGroup.calendarGroupId.mailboxInfo,
                accountKey
            )
    );

    return { userCalendarGroups, groupCalendarGroups, teamsCalendarGroups };
}

export function getCalendarsForUser(mailboxInfo: MailboxInfo) {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

    return getCalendarEntries().filter(calendarEntry =>
        isCalendarInAccount(calendarEntry, accountKey)
    );
}

export function getCalendarsForCalendarGroup(
    mailboxInfo: MailboxInfo,
    parentGroupId: string | undefined
): CalendarEntry[] {
    const accountKey = getAccountKeyForMailboxInfo(mailboxInfo);

    const calendars = getCalendarEntries().filter(calendarEntry => {
        return (
            calendarEntry &&
            calendarEntry.ParentGroupId === parentGroupId &&
            isSameCoprincipalAccountByMailboxAndAccountKey(
                calendarEntry.calendarId.mailboxInfo,
                accountKey
            )
        );
    });

    return calendars;
}

export function getShareableCalendars(): CalendarEntry[] {
    const result: CalendarEntry[] = [];
    getCalendarEntries().forEach(calendar => {
        if (calendar.CanShare) {
            result.push(calendar);
        }
    });
    return result;
}
