import type { AccTabItem } from 'accelerator-common/lib/AccTabList';
import { useRoleContext, UserRoles } from 'hybridspace-common/lib/context';
import { isFeatureEnabled } from 'owa-feature-flags';
import EarthFilled from 'owa-fluent-icons-svg/lib/icons/EarthFilled';
import EarthRegular from 'owa-fluent-icons-svg/lib/icons/EarthRegular';
import PeopleCommunityFilled from 'owa-fluent-icons-svg/lib/icons/PeopleCommunityFilled';
import PeopleCommunityRegular from 'owa-fluent-icons-svg/lib/icons/PeopleCommunityRegular';
import WindowDevToolsFilled from 'owa-fluent-icons-svg/lib/icons/WindowDevToolsFilled';
import WindowDevToolsRegular from 'owa-fluent-icons-svg/lib/icons/WindowDevToolsRegular';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isPlacesExploreEnabled } from 'places-settings';
import React from 'react';
import { DEV_PANEL, PEOPLE } from './HomeRoutes';
import strings from './useHybridspaceHomeLinks.locstring.json';

export default function useHybridspaceHomeLinks(): AccTabItem[] {
    const mailboxInfo = getModuleContextMailboxInfo();
    const isPlacesExplorePageEnabled = isPlacesExploreEnabled(mailboxInfo);
    const roleSwitcherContext = useRoleContext();
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'msplaces-devPanel' */
    const isDevPanelEnabled = isFeatureEnabled('msplaces-devPanel', mailboxInfo);
    /* eslint-disable-next-line owa-custom-rules/require-undefined-parameter -- (https://aka.ms/OWALintWiki)
     * Flight checks that supply MailboxInfo should be defined as AccountFeatureName value and should be checked using isAccountFeatureEnabled to ensure consistent checking.
     *	> The parameter mailboxInfo must be undefined. Feature flight: 'msplaces-disableRoleSwitcher' */
    const roleSwitcherDisabled = isFeatureEnabled('msplaces-disableRoleSwitcher', mailboxInfo);
    const shouldHideHomeLinks =
        !roleSwitcherDisabled && roleSwitcherContext.userRole !== UserRoles.Employee;

    return React.useMemo(() => {
        const workPlan = {
            id: isPlacesExplorePageEnabled ? 'Work Plan' : 'Dashboard',
            key: isPlacesExplorePageEnabled ? `/${PEOPLE}` : `/`,
            icon: PeopleCommunityRegular,
            activeIcon: PeopleCommunityFilled,
            textResourceId: strings.hybridspaceWorkPlanLink,
            ariaLabelResourceId: strings.hybridspaceWorkPlanLink,
            hidden: shouldHideHomeLinks,
        };

        const explore = {
            id: 'Explore',
            key: `/`,
            icon: EarthRegular,
            activeIcon: EarthFilled,
            textResourceId: strings.hybridspaceExplorePageLink,
            ariaLabelResourceId: strings.hybridspaceExplorePageLink,
            hidden: !isPlacesExplorePageEnabled || shouldHideHomeLinks,
        };

        const devPanel = {
            id: 'devPanel',
            key: `/${DEV_PANEL}`,
            icon: WindowDevToolsRegular,
            activeIcon: WindowDevToolsFilled,
            textResourceId: strings.hybridspaceDevPanelLink,
            ariaLabelResourceId: strings.hybridspaceDevPanelLink,
            hidden: !isDevPanelEnabled || shouldHideHomeLinks,
        };

        if (isPlacesExplorePageEnabled) {
            return [explore, workPlan, devPanel];
        } else {
            return [workPlan, devPanel];
        }
    }, [isPlacesExplorePageEnabled, shouldHideHomeLinks, isDevPanelEnabled]);
}
