import { LazyAction, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PlacesSignout" */ './lazyIndex')
);

export const lazyCleanUpPlacesLocalStorage = new LazyAction(
    lazyModule,
    m => m.cleanUpPlacesLocalStorage
);

export const lazyCleanUpPlacesSessionStorage = new LazyAction(
    lazyModule,
    m => m.cleanUpPlacesSessionStorage
);

export const lazyCleanUpPlacesIndexedDB = new LazyAction(lazyModule, m => m.cleanUpPlacesIndexedDB);
