import { createAccessors } from 'owa-viewstate-store';
import { createStore } from 'owa-satcheljs';

import type { CalendarEvent } from 'owa-graph-schema';
import type { DateRange } from 'owa-datetime-utils';
const getStore = createStore('places-calendar-view-store', {
    calendarEvents: null as Map<DateRange, CalendarEvent[]> | null,
});

const { getCalendarEvents, setCalendarEvents } = createAccessors(
    'places-calendar-view-store',
    getStore
);

export const addCalendarEvents = (dateRange: DateRange, events: CalendarEvent[]) => {
    const currentEvents = getCalendarEvents() || new Map<DateRange, CalendarEvent[]>();
    currentEvents.set(dateRange, events);
    setCalendarEvents(currentEvents);
};

export { getCalendarEvents };
