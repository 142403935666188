import { actionWithDatapoint } from 'owa-analytics-actions';
import { createStore } from 'owa-satcheljs';
import { mutator, mutatorAction, orchestrator } from 'satcheljs';
import { toJS } from 'mobx';
import createAccessors from './createAccessors';
import type { TraceErrorObject } from 'owa-trace';

/**
 * This function creates a store that tracks the data and load state of a given item.
 * This can be used to drive dialogs with interstitial screens, fields and save/cancel/close buttons.
 *
 * @param name
 * A name for the store.
 * This is also used to generate data point names.
 *
 * @param load
 * A callback that loads the item with the given id.
 * The application that implements this function can decide if it will use items from a local cache or
 * if it will issue a network call in order to fulfill the load operation.
 * Once the load operation is completed it must call `loaded` or `loadFailed` to update this store.
 * If this is the `start` function of an observable async operation (see createObservableOperation) then
 * orchestrators of the respective success & failure actions must call `loaded` or `loadFailed`, respectively.
 * If this is a plain async function, it should have a try/catch to properly report the errors.
 *
 * @param defaultState
 * A function that returns the default state of the store.
 * This is called whenever we reset the state of the store because of a change in create x edit mode and
 * when items are loaded. It must not contain 'id' or 'store' fields.
 *
 * @param createModeState
 * A function that returns the state for a new 'create' scenario.
 *
 * @param editModeState
 * A function that returns the state for a new 'edit' scenario.
 *
 * @param contextualState
 * An optional state that is preserved between state changes in create and edit scenarios.
 * The application must populate the contextual state before calling `open`,
 * using the accessors provided in the returned object.
 *
 * Make sure not to include values both here and in defaultState. This can lead to buggy behavior.
 *
 * @param disableUpdateBaselineAfterSave
 * An optional flag that disables the update of the baseline after the item is saved.
 * This can be used to fix the issue where changes made during saving get updated to the baseline.
 * If you use this flag, you should also manually update the baseline after saving (but with only the changes that were actually saved)
 *
 * @param keyEqual
 * An optional function that compares two keys for equality. Required if the key is not a primitive type.
 *
 * @returns
 * A set of functions that can be used to drive a create or edit dialog, including interstitial elements,
 * and accessors for all fields in the store.
 *
 * - `open`:       A function that starts the process of opening the dialog.
 *                 This will usually be called inside a useEffect in a React component.
 *                 When no id is given, this puts the store in create mode, after calling `createModeState`.
 *                 When an id is given, this puts the store in edit mode, `load` is called and the store
 *                 waits for `loaded` or `loadFailed` to be called.
 *                 The component that calls `open` will usually provide an `Interstitial` component
 *                 or some other type of UX to indicate that the item is being loaded and to handle load failures.
 * - `getId`:      A selector that returns the id of the item that is being edited or `null` when the dialog is in create mode.
 * - `getStore`:   A selector that returns the store data. The application should NEVER cache the resulting value in a module variable.
 * - `getBaseline`:A selector that returns the initial state after the store was opened. The application can compare the baseline to the store to determine if there are changes to be saved.
 * - `hasFailed`:  A selector that indicates if the load operation has failed. Can be used with `Interstitial` to show an error message.
 * - `isOpen`:     A selector that indicates if the dialog is open. Can be used with `Interstitial` to show a spinner.
 *                 When the store is in create mode, this is immediately true.
 *                 When the store is in edit mode, this is true once `loaded` is called.
 * - `loaded`:     An action that turns `isOpen` to true after updating the store by calling `editModeState`.
 *                 This action will only respond to the first call after `load` is called, to avoid cases where an item is reloaded
 *                 by reasons outside the control of the dialog, such as in response to notifications.
 * - `loadFailed`: An action that turns `hasFailed` to true.
 * - `reload`:     A function that reloads the item. Can be used with `Interstitial` to retry loading an item in a dialog.
 * - `close`:      An action that resets the store when the dialog closes. Can be used with `Interstitial` to close a dialog.
 *                 The dialog can attach a useOrchestrator hook to this action to report when it has closed to parent elements.
 * - `closing`:    An event that indicates the view state is closing. This fires before `close`, while data is still in the store.
 * - `saved`:      An action that indicates the object being edited was saved, which must be called by the corresponding save operation.
 * - `dispose`:    A function that can be returned from a useEffect call to close the dialog.
 * - `accessors`:  Additional functions for getting and setting values, for every property defined by the `CreateState`, `EditState` and `ContextualState` types.
 */
export default function createViewStateStore<
    Id,
    Item,
    CreateState extends Record<string, unknown>,
    EditState extends Record<string, unknown>,
    DefaultState extends CreateState & EditState,
    ContextualState extends Record<string, unknown>
>(
    name: string,
    load: (id: Id) => void,
    defaultState: () => DefaultState,
    createModeState: () => CreateState,
    editModeState: (item: Item) => EditState,
    contextualState?: ContextualState,
    disableUpdateBaselineAfterSave?: boolean,
    keyEqual: (a: Id | null, b: Id | null) => boolean = (a, b) => a === b
) {
    // Store
    const store = createStore(name, {
        id: null as Id | null,
        status: 'closed' as 'closed' | 'loading' | 'open' | 'failed',
        loadError: undefined as TraceErrorObject | undefined,
        baseline: defaultState(),
        state: defaultState(),
        context: { ...contextualState } as ContextualState,
    })();

    // Selectors
    const getId = () => store.id;
    const getStore = () => store.state;
    const getContext = () => store.context;
    const hasFailed = () => store.status === 'failed';
    const isOpen = () => store.status === 'open';
    const getLoadError = () => store.loadError;
    const getBaseline = () => store.baseline;

    // Functions
    function open(id: Id | null | undefined, prefilledState?: CreateState) {
        if (id) {
            openInEditMode(id);
        } else {
            openInCreateMode(prefilledState);
            opened();
        }
    }

    function reload() {
        if (store.id) {
            load(store.id);
        }
    }

    function updateBaseline() {
        store.baseline = JSON.parse(JSON.stringify(store.state));
    }

    // Public Actions
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const opened = actionWithDatapoint(name + '_OPENED');
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const loaded = actionWithDatapoint(name + '_LOADED', (id: Id, item: Item) => ({
        id,
        item,
    }));
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const loadFailed = actionWithDatapoint(
        name + '_LOAD_FAILED',
        (id: Id, error?: TraceErrorObject) => ({ id, error })
    );
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const closing = actionWithDatapoint(name + '_CLOSING');
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const close = actionWithDatapoint(name + '_CLOSE');
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const saved = actionWithDatapoint(name + '_SAVED', (id: Id) => ({ id }));

    function dispose() {
        close();
    }

    // Private Actions
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const openInCreateMode = actionWithDatapoint(
        name + '_OPEN_CREATE',
        (prefilledState?: CreateState) => ({ prefilledState })
    );
    /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
     * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
     *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
    const openInEditMode = actionWithDatapoint(name + '_OPEN_EDIT', (id: Id) => ({ id }));

    // Mutators & Orchestrators
    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(openInCreateMode, ({ prefilledState }) => {
        store.id = null;
        store.status = 'open';
        store.state = {
            ...defaultState(),
            ...(prefilledState ? prefilledState : createModeState()),
        };
        updateBaseline();
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(openInEditMode, ({ id }) => {
        store.id = id;
        store.status = 'loading';
        store.state = defaultState();
        updateBaseline();
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(saved, ({ id }) => {
        if (store.id === null) {
            store.id = id;
        }

        if (!store.baseline || !disableUpdateBaselineAfterSave) {
            updateBaseline();
        }
    });
    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Move this function to the module scope or wrapped it on a once function
     *	> Function should only be invoked in module scope */
    orchestrator(openInEditMode, ({ id }) => {
        load(id);
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(loaded, ({ id, item }) => {
        if (keyEqual(id, getId())) {
            store.state = {
                ...defaultState(),
                ...editModeState(item),
            };
            updateBaseline();
            store.status = 'open';
            store.loadError = undefined;
        }
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Move this function to the module scope or wrapped it on a once function
     *	> Function should only be invoked in module scope */
    orchestrator(loaded, () => {
        opened();
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(loadFailed, ({ id, error }) => {
        if (keyEqual(id, getId())) {
            store.status = 'failed';
            store.loadError = error;
        }
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Move this function to the module scope or wrapped it on a once function
     *	> Function should only be invoked in module scope */
    orchestrator(close, () => {
        closing();
    });

    /* eslint-disable-next-line owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * * Baseline. DO NOT COPY AND PASTE!
     *	> Function should only be invoked in module scope */
    mutator(close, () => {
        store.id = null;
        store.status = 'closed';
        store.state = defaultState();
        store.context = { ...contextualState } as ContextualState;
        updateBaseline();
    });

    const stateAccessors = createAccessors(name, getStore);
    const contextAccessors = createAccessors(name, getContext);

    function createSnapshot() {
        return toJS(store);
    }
    /* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables, owa-custom-rules/invoke-only-in-module-scope -- (https://aka.ms/OWALintWiki)
     * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
     * undefined
     *	> Please add 'Mutator' substring add the end of the mutator action variable name.
     (https://aka.ms/OWALintWiki)
         * Baseline. DO NOT COPY AND PASTE!
         *	> Function should only be invoked in module scope */
    const restoreSnapshot = mutatorAction(name + '_RESTORE', (snapshot: typeof store) => {
        store.id = snapshot.id;
        store.status = snapshot.status;
        store.loadError = snapshot.loadError;
        store.baseline = snapshot.baseline;
        store.state = { ...defaultState(), ...snapshot.state };
        store.context = { ...contextualState, ...snapshot.context };
    });

    return {
        ...stateAccessors,
        ...contextAccessors,
        open,
        getId,
        getStore,
        getBaseline,
        getLoadError,
        hasFailed,
        isOpen,
        opened,
        loaded,
        loadFailed,
        reload,
        saved,
        closing,
        close,
        dispose,
        createSnapshot,
        restoreSnapshot,
    };
}
