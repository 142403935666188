let upsBaseUrl: string;
let _lazyIsInitialized = false;

const initializeBaseUrl = () => {
    upsBaseUrl = 'https://teams.cloud.microsoft/ups/global';
};

export function getUPSBaseUrl() {
    if (!_lazyIsInitialized) {
        initializeBaseUrl();
        _lazyIsInitialized = true;
    }
    return upsBaseUrl;
}

export function setUPSBaseUrl(url: string) {
    upsBaseUrl = url;
}
