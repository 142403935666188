/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * BASELINE. Do not copy and paste!
 *	> 'dexie' import is restricted from being used. Importing from dexie is allowed but needs an additional approver */
import Dexie from 'dexie';

import { logGreyError } from 'owa-analytics';

const INDEXED_DB_NAME = 'placesServiceCache'; // Cache to help places service recover from network errors
const TABLE_NAME = 'placesBuildings';

// Buildings Cache
export interface BuildingsCache {
    uniqueId: string; // building ID
    owner: string;
    building: string;
    timestamp: number;
}

export const db = new Dexie(INDEXED_DB_NAME);

db.version(1).stores({
    placesBuildings: 'uniqueId,owner',
});

export const buildingsTable = db.table(TABLE_NAME);

export async function deleteServiceCache() {
    if (await Dexie.exists(INDEXED_DB_NAME)) {
        try {
            if (db.isOpen()) {
                db.close(); // Have to close the database before deleting, otherwise InvalidStateError will be thrown.
            }
        } catch (err) {
            logGreyError('PlacesCloseDatabaseError', err, { dbName: INDEXED_DB_NAME });
        }
        await Dexie.delete(INDEXED_DB_NAME);
    }
}
